import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { FdrCo2Emission } from 'fdr/components/local/fdr-co2-emission/fdr-co2-emission';
import { FdrOfferDatesInformation } from 'fdr/components/local/fdr-offer/fdr-offer-dates-information';
import { FdrFromTo } from 'fdr/components/local/fdr-page-components/recommendations/product/fdr-product-recommendation.styles';
import FdrProductCardThird from 'fdr/components/local/fdr-product-card/product-card-third/fdr-product-card-third';
import { FdrOfferBreadcrumbs } from './fdr-offer-breadcrumbs';
import { IFdrOfferItemProps } from './fdr-offers.types';

const FdrSrlOfferCloseButton = styled(BsButtonWithIcon)(
  ({ theme: { shadows } }) => ({
    borderRadius: '50%',
    boxShadow: shadows.cardShadow,
    position: 'absolute',
    zIndex: 2,
    right: '13px',
    top: '10px',
    padding: '6px',
    ':after': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      padding: '25px',
    },
  })
);

const FdrSrlOfferProductCard = styled(FdrProductCardThird)(
  ({ theme: { media }, theme }) => ({
    '.productFeatures .icon': {
      ...theme.icons.md,
      [media.tablet]: {
        ...theme.icons.sm,
      },
    },
  })
);

const FdrSrlCo2EmissionAndMealType = styled.div(
  ({ theme: { FONT_SIZE, space } }) => ({
    ...FONT_SIZE.MEDIUM,
    display: 'flex',
    alignItems: 'center',
    paddingTop: space[1],
    width: '100%',
    flexWrap: 'nowrap',
    '.icon': {
      marginRight: space[2],
      flexShrink: 0,
    },
    '.delimiter': {
      marginRight: space[2],
      marginLeft: space[2],
    },
    '.mealType': {
      whiteSpace: 'nowrap',
    },
  })
);

const FdrSrlCo2Emission = styled(FdrCo2Emission)(
  ({ theme: { media, space } }) => ({
    width: '100%',
    [media.mobile]: {
      paddingTop: space[2],
    },
  })
);

const FdrSrlOfferDatesInformationWrapper = styled.span<{
  isTopPadding: boolean;
}>(({ theme: { space }, isTopPadding }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: isTopPadding && space[3],
  'white-space': `pre-wrap`,
  '.icon': {
    marginRight: space[2],
    flexShrink: 0,
  },
}));

export const FdrSrlOfferItem: React.FC<IFdrOfferItemProps> = ({
  price,
  link,
  disrupter,
  duration,
  productFeatures,
  productTravelType,
  departureDate,
  returnDate,
  breadcrumbs,
  fromTo,
  distanceInfo,
  className,
  openMethod,
  mealType,
  productType,
  onClose,
  transferPrice,
  transferIncluded,
  travelType,
  onClick,
  image,
  testId,
  rating,
  title,
  ta,
  travelers,
  co2Emission,
}) => {
  const [t] = useTranslation(['results', 'common']);
  const { mobile } = useDeviceType();

  const isRoundtripOrCruise = ['FdrCruise', 'FdrRoundTrip'].includes(
    productType
  );
  const isShowMealType = !isRoundtripOrCruise && mealType;

  const handleClose = useMemo(
    () =>
      onClose
        ? (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }
        : undefined,
    [onClose]
  );

  return (
    <FdrSrlOfferProductCard
      distanceInfo={distanceInfo}
      image={image}
      productFeatures={productFeatures}
      productType={productType}
      disrupter={disrupter}
      link={link}
      title={title}
      openMethod={openMethod}
      price={price}
      ta={ta}
      rating={rating}
      subTitle={<FdrOfferBreadcrumbs breadcrumbs={breadcrumbs} />}
      productTravelType={productTravelType}
      testId={testId}
      className={className}
      closeButton={
        handleClose && mobile ? (
          <FdrSrlOfferCloseButton
            variant={E_BUTTON_TYPE.ICON_BUTTON}
            icon={{ name: 'close', size: 'xs' }}
            onClick={handleClose}
            className="offer-close-btn"
          />
        ) : null
      }
      transferPrice={transferPrice}
      transferIncluded={transferIncluded}
      travelType={travelType}
      onClick={onClick}
    >
      {departureDate && returnDate && duration ? (
        <FdrSrlOfferDatesInformationWrapper isTopPadding={isRoundtripOrCruise}>
          <BsIcon name="calendar" />
          <FdrOfferDatesInformation
            departureDate={departureDate}
            returnDate={returnDate}
            duration={duration}
            travelersCount={
              !isRoundtripOrCruise ? (
                <span data-id="offer-travelers">
                  {' | '}
                  {t('common:adult.count', { count: travelers.adults })}
                  {travelers.children > 0 &&
                    `, ${t('common:child.count', {
                      count: travelers.children,
                    })}`}
                </span>
              ) : null
            }
          />
        </FdrSrlOfferDatesInformationWrapper>
      ) : null}
      {fromTo && <FdrFromTo>{fromTo}</FdrFromTo>}

      <FdrSrlCo2EmissionAndMealType>
        {isShowMealType ? (
          <>
            <BsIcon name="boardtype" />
            <div className="mealType">{mealType}</div>
          </>
        ) : null}
        {(co2Emission?.flightEmissionKgCO2e ||
          co2Emission?.hotelEmissionKgCO2e) &&
        !mobile ? (
          <>
            {isShowMealType ? <div className="delimiter">{'|'}</div> : null}
            <FdrSrlCo2Emission
              flightEmission={co2Emission.flightEmissionKgCO2e}
              hotelEmission={co2Emission.hotelEmissionKgCO2e}
            />
          </>
        ) : null}
      </FdrSrlCo2EmissionAndMealType>
      {(co2Emission?.flightEmissionKgCO2e ||
        co2Emission?.hotelEmissionKgCO2e) &&
      mobile ? (
        <FdrSrlCo2Emission
          flightEmission={co2Emission.flightEmissionKgCO2e}
          hotelEmission={co2Emission.hotelEmissionKgCO2e}
        />
      ) : null}
    </FdrSrlOfferProductCard>
  );
};
