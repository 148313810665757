import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { card } from '@hotelplan/components.common.mixins';
import { FdrImage } from '@hotelplan/fdr.regular.basis.fdr-image';
import { FdrFeaturesSummary } from '@hotelplan/fdr.regular.fusion.fdr-feature';
import { FdrTripAdvisorInfo } from '@hotelplan/fdr.regular.fusion.fdr-trip-advisor';
import { FdrLazyRating } from 'fdr/components/local/fdr-lazy-rating';

export const FdrProductCardContentWrap = styled.div.attrs({
  className: 'cardContentWrap',
})(({ theme: { space, media } }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  [media.tablet]: {
    flexBasis: '470px',
  },
  '.cardContentText': {
    display: 'flex',
    flexDirection: 'column',
    padding: space[3],
    paddingBottom: 0,
    [media.tablet]: {
      paddingLeft: space[5],
      paddingRight: space[5],
      padding: `0 0 0 ${space[3]}`,
    },
  },
  '.rating-wrap': {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const FdrProductCardRows = styled.div.attrs({
  className: 'card-rows',
})(({ theme: { radii } }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  width: '100%',
  '> .card-row:first-child': {
    borderRadius: radii.roundedTop,
  },
  '> .card-row:last-child': {
    borderRadius: radii.roundedBottom,
  },
  '.card-header + .cardContent': {
    borderTop: 'none',
  },
}));

export const FdrProductCardImageWrap = styled.div.attrs({
  className: 'cardImageWrap',
})(
  sx2CssThemeFn({
    minHeight: [null, '200px'],
    position: 'relative',
    flexBasis: [null, '298px'],
    height: ['147px', '100%'],
    flexShrink: [null, '0'],
    borderRadius: '3px 3px 0 0',
    overflow: 'hidden',
    a: {
      height: '100%',
    },
  })
);

export const FdrProductTravelTypeTitle = styled.span(
  ({ theme: { space } }) => ({
    display: 'flex',
    alignItems: 'center',
    '.icon': {
      verticalAlign: 'sub',
      marginRight: space[1],
    },
  })
);

export const FdrTopOffer = styled.span.attrs({
  className: 'topOffer',
})(({ theme: { FONT_SIZE, colors } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.SMALL,
    padding: 2,
    paddingRight: '14px',
    paddingLeft: [2, '14px'],
    position: 'absolute',
    borderRadius: '0 0 5px 5px',
    backgroundColor: colors.mainRed,
    color: 'white',
    zIndex: 1,
    top: 0,
    '& > span': {
      display: 'flex',
      alignItems: 'center',
    },
    '.icon': {
      verticalAlign: 'text-bottom',
      marginRight: 1,
    },
  })
);

export const FdrProductCardImage = styled(FdrImage)({
  display: 'block',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
});

export const FdrProductCardTitle = styled.h4.attrs({
  className: 'cardTitle',
  'data-id': `product-title`,
})(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.XS,
    marginBottom: [1, 0],
  })
);

export const FdrProductCardSubTitle = styled.span.attrs({
  className: 'cardSubTitle',
})(({ theme: { colors, FONT_SIZE } }) =>
  sx2CssThemeFn({
    color: colors.darkGray,
    ...FONT_SIZE.MEDIUM,
    marginBottom: 1,
  })
);

export const FdrProductFeaturesSummary = styled(FdrFeaturesSummary)(
  ({ theme: { space, icons, FONT_SIZE } }) => ({
    paddingTop: space[1],
    '.featureGroupWrapper': {
      marginRight: space[3],
      marginBottom: space[1],
      lineHeight: '1',
    },
    '&&& .feature-summary-item-icon': icons.md,
    '.feature-summary-item-name': FONT_SIZE.MEDIUM,
  })
);

export const FdrDistanceInfo = styled.span(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.MEDIUM,
    marginBottom: 1,
  })
);

export const FdrProductCardHeader = styled.div.attrs({
  className: 'card-header card-row',
  'data-id': `product-header`,
})({ width: '100%' });

export const FdrTripAdvisorInfoWrapper = styled(FdrTripAdvisorInfo)(
  ({ theme: { FONT_SIZE } }) =>
    sx2CssThemeFn({
      pt: 1,
      img: {
        height: '20px',
        width: '30px',
        mr: 2,
      },
      '.icon': {
        width: '13px',
        height: '13px',
      },
      '.tripAdvisorInfoText': {
        ...FONT_SIZE.SMALLER,
        color: 'darkGray',
        mt: [0, '2px'],
      },
    })
);

export const FdrProductCardContent = styled.div(
  ({ theme: { colors, media, radii, space } }) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    width: '100%',
    backgroundColor: colors.background,
    border: 'none',
    '.cardImageWrap': {
      borderRadius: radii.default,
    },
    [media.tablet]: {
      padding: space[3],
      flexDirection: 'row',
    },
  })
);

export const FdrProductCardWrap = styled.div(
  sx2CssThemeFn(card),
  sx2CssThemeFn(({ theme: { colors, media, FONT_SIZE, radii } }) => ({
    border: '1px solid',
    borderColor: colors.lightGreyFirst,
    borderRadius: radii.default,
    display: 'flex',
    position: 'relative',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: '100%',
    [media.tablet]: {
      flexDirection: 'row',
    },
    '.disrupter': {
      ...FONT_SIZE.LARGE_BOLD,
      lineHeight: [1.5, 1],
    },
    '.disrupter ~ .travelType': {
      top: '41px',
      [media.tablet]: {
        top: '50px',
      },
    },
    '.card-row': {
      overflow: 'visible',
      '.featureGroupWrapper': {
        '&:hover': {
          '.tooltip': {
            zIndex: '10',
          },
        },
      },
    },
  }))
);

export const FdrTravelTypeWrap = styled.div(
  ({ theme: { media, space, radii } }) => ({
    position: 'absolute',
    right: 'auto',
    left: 'auto',
    top: 'auto',
    margin: space[2],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '.topOffer': {
      borderRadius: radii.default,
      position: 'relative',
      left: space[2],
      padding: `${space[1]} 10px`,
    },
    [media.tablet]: {
      margin: space[4],
      right: 'auto',
      '.topOffer': {
        position: 'relative',
        right: 'auto',
        left: space[2],
      },
    },
  })
);

export const FdrProductCardRating = styled(FdrLazyRating)(
  ({ theme: { colors, media, space } }) => ({
    marginBottom: space[2],
    color: colors.primary,
    '.rating': {
      marginRight: '3px',
    },
    '.remainRating': {
      marginRight: '3px',
      color: colors.lightGreyFirst,
    },
    [media.tablet]: {
      marginBottom: '12px',
    },
  })
);

export const FdrProductCardTravelType = styled.span(
  ({ theme: { radii, colors, space, FONT_SIZE } }) => ({
    ...FONT_SIZE.SMALL,
    padding: `${space[1]} 10px!important`,
    position: 'relative',
    top: '0px',
    borderRadius: radii.default,
    backgroundColor: colors.lightGreySecond,
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    '.icon': {
      marginRight: '3px',
    },
    '.delimiter': {
      padding: `0 ${space[1]}`,
      px: '3px',
    },
  })
);
