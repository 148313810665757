import { useTranslation } from 'next-i18next';
import React from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { FdrTransferInfo } from '@hotelplan/fdr.regular.basis.fdr-transfer-info';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { mapFeatureNameToIcon } from '@hotelplan/libs.mappers';
import Disrupter from 'components/domain/disrupter/Disrupter';
import { FdrProductCardFooter } from './fdr-product-card-footer';
import { FdrProductCardWrapper } from './fdr-product-card-wrapper';
import { FDR_TOP_HOTEL_OFFER_ID } from './fdr-product-card.constants';
import {
  FdrDistanceInfo,
  FdrProductCardHeader,
  FdrProductCardImage,
  FdrProductCardImageWrap,
  FdrProductCardRating,
  FdrProductCardRows,
  FdrProductCardSubTitle,
  FdrProductCardTitle,
  FdrProductFeaturesSummary,
  FdrProductCardTravelType,
  FdrProductTravelTypeTitle,
  FdrTopOffer,
  FdrTripAdvisorInfoWrapper,
  FdrProductCardWrap,
  FdrTravelTypeWrap,
  FdrProductCardContentWrap,
  FdrProductCardContent,
} from './fdr-product-card.styles';
import { IFdrProductCardProps } from './fdr-product-card.types';

export const FdrProductCard: React.FC<IFdrProductCardProps> = ({
  productTravelType,
  image,
  rating,
  ta,
  title,
  subTitle,
  closeButton,
  children,
  productFeatures,
  price,
  link,
  onClick,
  testId,
  disrupter,
  distanceInfo,
  productType,
  className,
  transferPrice,
  transferIncluded,
  header,
  isSingle = false,
  openMethod,
}) => {
  const [t] = useTranslation(['common', 'srl']);
  const { mobile } = useDeviceType();

  const topOffer = productFeatures?.filter(
    item => item.id === FDR_TOP_HOTEL_OFFER_ID
  );

  const showDistanceInfo = !!distanceInfo && productType === 'FdrHotel';
  const showTransferInfo = !!transferPrice || transferIncluded;

  return (
    <FdrProductCardWrapper
      link={{ ...link, openMethod: openMethod ? openMethod : link?.openMethod }}
      onClick={onClick}
    >
      <FdrProductCardWrap className={className} data-id={testId}>
        {disrupter && (
          <Disrupter
            dangerouslySetInnerHTML={{ __html: disrupter }}
            className="disrupter"
            data-id="disrupter"
          />
        )}
        <FdrTravelTypeWrap className="travelType">
          {productTravelType && (
            <FdrProductCardTravelType>
              {productTravelType.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <FdrProductTravelTypeTitle>
                      <Icon name={item.icon} size="md" />
                      {t(item.title)}
                    </FdrProductTravelTypeTitle>
                    {productTravelType.length - 1 !== i && (
                      <div className="delimiter"> + </div>
                    )}
                  </React.Fragment>
                );
              })}
            </FdrProductCardTravelType>
          )}
          {topOffer?.length ? (
            <FdrTopOffer>
              {topOffer.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <span>
                      <Icon name={mapFeatureNameToIcon(item.id)} size="md" />
                      {t(item.name)}
                    </span>
                  </React.Fragment>
                );
              })}
            </FdrTopOffer>
          ) : null}
        </FdrTravelTypeWrap>

        <FdrProductCardRows>
          {header && <FdrProductCardHeader>{header}</FdrProductCardHeader>}

          <FdrProductCardContent className="cardContent card-row">
            {image && (
              <FdrProductCardImageWrap>
                <FdrProductCardImage image={image} />
                {closeButton}
              </FdrProductCardImageWrap>
            )}

            <FdrProductCardContentWrap className="cardContentWrap">
              <div className="cardContentText">
                {rating > 0 && (
                  <div className="rating-wrap">
                    <FdrProductCardRating
                      maxRating={5}
                      rating={rating}
                      ratingContent={<Icon name="hotelplanbewertung" />}
                      halfWayRatingContent={
                        <Icon name="hotelplanbewertung-outlined" />
                      }
                    />
                  </div>
                )}
                {title && <FdrProductCardTitle>{title}</FdrProductCardTitle>}
                {subTitle && (
                  <FdrProductCardSubTitle>{subTitle}</FdrProductCardSubTitle>
                )}
                {children}
                {showDistanceInfo ? (
                  <FdrDistanceInfo>
                    {`${t('distance.info')}: ${distanceInfo.toString()} km`}
                  </FdrDistanceInfo>
                ) : null}
                {showTransferInfo && (
                  <FdrDistanceInfo>
                    <FdrTransferInfo
                      price={transferPrice}
                      transferIncluded={transferIncluded}
                    />
                  </FdrDistanceInfo>
                )}
                {productFeatures?.length ? (
                  <FdrProductFeaturesSummary
                    groups={productFeatures}
                    successIcon={`checkmark-black`}
                  />
                ) : null}
                {ta?.rating ? (
                  <FdrTripAdvisorInfoWrapper
                    rating={ta.rating || 0}
                    count={ta.reviews || 0}
                    label={t(
                      `srl:offers.${
                        mobile ? 'tripAdvisorMobile' : 'tripAdvisor'
                      }`
                    )}
                    className="card-trip-advisor"
                  />
                ) : null}
                {isSingle ? <FdrProductCardFooter price={price} /> : null}
              </div>
            </FdrProductCardContentWrap>
          </FdrProductCardContent>

          {!isSingle ? <FdrProductCardFooter price={price} /> : null}
        </FdrProductCardRows>
      </FdrProductCardWrap>
    </FdrProductCardWrapper>
  );
};
